<template>
  <div class="textBook_manage" style="padding-bottom: 20px;">
    <!-- 条件搜索部分 -->
    <el-form class="zwx-form" key="msSearchForm" ref="msSearchForm" :model="msSearchForm" label-position="right" @submit.native.prevent>
      <div class="condition-row">
        <el-form-item label="行政区划：" label-width="70px">
          <zwx-select-area class="cascader-area-biz-zone" ref="bizZoneArea" @change="bizZoneChange" @loadOver="areaIdLoadOver()" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" :clearable="false" />
        </el-form-item>
        <el-form-item label="企业名称：" label-width="70px">
          <el-input class="zwx-input" style="width:220px !important" v-model.trim="msSearchForm.unitName" placeholder="" clearable />
        </el-form-item>
        <el-form-item label="学员姓名：" label-width="70px">
          <el-input class="zwx-input" style="width:220px !important" v-model.trim="msSearchForm.userName" placeholder="" clearable />
        </el-form-item>
        <!--
        <el-form-item label="文件类别：" label-width="70px">
          <el-select class="zwx-select " popper-class="zwx-select-popper" v-model="msSearchForm.fileTypeArr" multiple collapse-tags placeholder="请选择" clearable>
            <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        -->
        <div style="clear: both" />
      </div>
      <div class="condition-row">
        <el-form-item label="证件号码：" label-width="70px">
          <el-input class="zwx-input" style="width:220px !important" v-model.trim="msSearchForm.idc" placeholder="" clearable />
        </el-form-item>
        <el-form-item label="课程名称：" label-width="70px">
          <el-input class="zwx-input" style="width:220px !important" v-model.trim="msSearchForm.courseName" placeholder="" clearable />
        </el-form-item>
        <el-form-item label="学习状态：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msSearchForm.studyStateArr">
            <el-checkbox class="zwx-checkbox" label="0">未开始</el-checkbox>
            <el-checkbox class="zwx-checkbox" label="1">进行中</el-checkbox>
            <el-checkbox class="zwx-checkbox" label="2">已完成</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div style="clear: both" />
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
    </div>

    <!-- 列表展示部分 -->
    <el-table class="zwx-table" row-key="rid" :data="dataList" border stripe tooltip-effect="light">
      <el-table-column prop="zoneName" label="行政区划" min-width="150" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.zoneName || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="unitName" label="企业名称" min-width="220" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.unitName || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="学员姓名" width="120" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ row.userName || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="idc" label="证件号码" width="160" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ row.idc || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="courseName" label="课程名称" min-width="220" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.courseName || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="traineeTypeName" label="学员类型" width="150" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ row.traineeTypeName || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="trainingTypeName" label="培训类型" width="90" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ row.trainingTypeName || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="alreadyClassHoure" label="已完成学时" width="100" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ row.alreadyClassHoure ? parseFloat(row.alreadyClassHoure) : '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="allRequiredClassHoure" label="学时要求" width="90" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ row.allRequiredClassHoure ? parseFloat(row.allRequiredClassHoure) : '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="courseBuyType" label="课程类别" width="120" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ { 1: '培训班课程', 2: '自购课程' }[row.courseBuyType] || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="activityMainExp" label="来源" min-width="220" header-align="left" align="left">
        <template slot-scope="{ row }">{{ { 1: '培训班课程', 2: '自购课程' }[row.courseBuyType] || '-' }}：{{ (row.activityMainExp && row.activityMainExp.trainingActivityName) || '-' }}</template>
      </el-table-column>
    </el-table>
    <base-pagination class="normal-pagination" :parentPage="currentPage" :pageSize="pageSize" :total="total" @currentChange="search" />
  </div>
</template>

<script>
export default {
  name: 'CourseRecord',
  components: {},
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      //查询对象
      msSearchForm: {
        zoneCode12: '',
        unitName: '',
        userName: '',
        idc: '',
        courseName: '',
        studyStateArr: ['2'],
      },
      zoneCode12From: '',
      //下方数据表格集合
      currentPage: 1,
      pageSize: 18,
      total: 0,
      dataList: [],
    }
  },
  mounted() {
    // this.search(1)
    // 赋值当前用户所属地区
    let config = window.top.vm.$store.state.config
    this.zoneCode12From = config.empower //'320200000000'
    this.$nextTick(() => {
      this.$refs.bizZoneArea.initSearch(this.zoneCode12From)
      this.$forceUpdate()
    })
  },
  activated() {
    if (this.$route.params.fresh) this.search(1)
  },
  methods: {
    areaIdLoadOver() {
      this.search(1)
    },
    bizZoneChange() {
      this.msSearchForm.zoneCode12 = this.$refs.bizZoneArea.getChooseArea().zoneCode12
    },
    //查询
    search(currentPage) {
      this.$emit('loading', true)
      this.currentPage = currentPage
      this.msSearchForm.zoneCode12 = this.$refs.bizZoneArea.getChooseArea().zoneCode12
      let data = {
        currentPage: this.currentPage,
        ...this.msSearchForm,
        idc: this.msSearchForm.idc && this.$zwxSm.sm4JsEncrypt(this.msSearchForm.idc),
      }
      this.$system.postJson(
        this.api + '/training/platform/getCourseLearningRecordsList-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.dataList = data.resultList
            this.total = data.resultCount
            this.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$emit('loading', false)
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .zwx-input {
  width: 220px !important;
}
</style>

<style lang="less"></style>
