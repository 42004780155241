var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detection-report-basic-info-edit" },
    [
      _c("zwx-step", { attrs: { index: 1, dataList: _vm.dataList } }),
      _c("el-divider", { staticClass: "base-divider" }),
      _c(
        "el-form",
        {
          key: "addForm",
          ref: "addForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "单位名称：",
                    prop: "employerName"
                  }
                },
                [
                  _c("unit-select", {
                    ref: "unitSelectRef",
                    attrs: {
                      width: "350px",
                      placeholderText: "请输入5个字符搜索",
                      requestUrl: _vm.requestUrl,
                      ifShowMoreBtn: false,
                      ifNeedMore: false
                    },
                    on: {
                      unitNameSearch: _vm.unitNameSearch,
                      unitNameDel: _vm.unitNameDel,
                      change: _vm.unitNameSelect,
                      loading: data => _vm.$emit("loading", data)
                    },
                    model: {
                      value: _vm.addForm.employerName,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "employerName", $$v)
                      },
                      expression: "addForm.employerName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "社会信用代码：",
                    prop: "creditCode"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "350px !important" },
                    attrs: {
                      placeholder: "请输入社会信用代码",
                      clearable: "",
                      disabled: _vm.creditCodeDisabled
                    },
                    model: {
                      value: _vm.addForm.creditCode,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "creditCode", $$v)
                      },
                      expression: "addForm.creditCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "行政区划：",
                    prop: "bizZone"
                  }
                },
                [
                  _c("zwx-select-area", {
                    ref: "zoneCodeArea",
                    staticClass: "select-component",
                    attrs: {
                      placeholder: "请选择",
                      zoneCode12From: _vm.zoneCode12From,
                      zoneTypeMax: "0",
                      zoneTypeMin: "4",
                      showFullName: true,
                      disabled: _vm.zoneDisabled
                    },
                    on: { change: _vm.nativePlaceChange }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "报告编号：",
                    prop: "reportCode"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "350px !important" },
                    attrs: { placeholder: "", clearable: "", maxlength: "200" },
                    model: {
                      value: _vm.addForm.reportCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "reportCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.reportCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "报告日期：",
                    prop: "reportDate"
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "350px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期",
                      "picker-options": _vm.$validate.noOverToday()
                    },
                    model: {
                      value: _vm.addForm.reportDate,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "reportDate", $$v)
                      },
                      expression: "addForm.reportDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "检测类型：",
                    prop: "detectionType"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "zwx-radio-group",
                      staticStyle: { "min-width": "260px !important" },
                      model: {
                        value: _vm.addForm.detectionType,
                        callback: function($$v) {
                          _vm.$set(_vm.addForm, "detectionType", $$v)
                        },
                        expression: "addForm.detectionType"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 1 } },
                        [_vm._v("定期检测")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 3 } },
                        [_vm._v("监督检测")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 2 } },
                        [_vm._v("现状评价")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-right": "0px !important" },
                  attrs: {
                    "label-width": "110px",
                    label: "现场采样日期：",
                    prop: "samplingBeginDate"
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期",
                      "picker-options": _vm.$validate.noBigDateNoOverToday(
                        _vm.$data["addForm"],
                        "samplingEndDate",
                        true
                      )
                    },
                    model: {
                      value: _vm.addForm.samplingBeginDate,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "samplingBeginDate", $$v)
                      },
                      expression: "addForm.samplingBeginDate"
                    }
                  }),
                  _c("span", { staticStyle: { margin: "0px 8px" } }, [
                    _vm._v("—")
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-left": "0px" },
                  attrs: { prop: "samplingEndDate" }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期",
                      "picker-options": _vm.$validate.noSmallDateNoOverToday(
                        _vm.$data["addForm"],
                        "samplingBeginDate",
                        true
                      )
                    },
                    model: {
                      value: _vm.addForm.samplingEndDate,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "samplingEndDate", $$v)
                      },
                      expression: "addForm.samplingEndDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "111px",
                    label: "陪同人：",
                    prop: "accompanyingPerson"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "350px !important" },
                    attrs: { placeholder: "", clearable: "", maxlength: "50" },
                    model: {
                      value: _vm.addForm.accompanyingPerson,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "accompanyingPerson",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.accompanyingPerson"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-right": "0px !important" },
                  attrs: {
                    "label-width": "110px",
                    label: "检验日期：",
                    prop: "detectionBeginDate"
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期",
                      "picker-options": _vm.$validate.noBigDate(
                        _vm.$data["addForm"],
                        "detectionEndDate",
                        true
                      )
                    },
                    model: {
                      value: _vm.addForm.detectionBeginDate,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "detectionBeginDate", $$v)
                      },
                      expression: "addForm.detectionBeginDate"
                    }
                  })
                ],
                1
              ),
              _c("span", { staticStyle: { margin: "0px 8px" } }, [_vm._v("—")]),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-left": "0px" },
                  attrs: { prop: "detectionEndDate" }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期",
                      "picker-options": _vm.$validate.noSmallDate(
                        _vm.$data["addForm"],
                        "detectionBeginDate",
                        true
                      )
                    },
                    model: {
                      value: _vm.addForm.detectionEndDate,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "detectionEndDate", $$v)
                      },
                      expression: "addForm.detectionEndDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "环境条件：",
                    prop: "enviromentCondition"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-textarea",
                    staticStyle: { width: "804px !important" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4 },
                      maxlength: "2000",
                      placeholder: "正文内容",
                      clearable: ""
                    },
                    model: {
                      value: _vm.addForm.enviromentCondition,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "enviromentCondition",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.enviromentCondition"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "报告附件：",
                    prop: "annexList1"
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "file-upload",
                        {
                          ref: "msPositive1",
                          attrs: {
                            accept: ".pdf,.PDF",
                            size: 50 * 1024 * 1024,
                            sizeInfo: "50M"
                          },
                          on: {
                            showFileList: _vm.clickPositive,
                            success: _vm.clickPositiveSuccess,
                            error: function($event) {
                              return _vm.clickError()
                            }
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-icontext-28",
                              attrs: { icon: "el-icon-upload2" }
                            },
                            [_vm._v("上传")]
                          )
                        ],
                        1
                      ),
                      _c("span", { staticClass: "upload-tip" }, [
                        _vm._v("支持多个pdf格式文件，不大于50M")
                      ])
                    ],
                    1
                  ),
                  _vm.addForm.annexList1.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "show-file",
                          staticStyle: { display: "inline-block" }
                        },
                        _vm._l(_vm.addForm.annexList1, function(item, index) {
                          return _c(
                            "div",
                            {
                              key: item.fileName,
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              },
                              on: {
                                mouseenter: function($event) {
                                  return _vm.mouseenter(index)
                                },
                                mouseleave: function($event) {
                                  return _vm.mouseleave(index)
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "file-name-hidden" }, [
                                _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      color: "#194DFF !important",
                                      "margin-left": "0px",
                                      cursor: "pointer"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openFilePreview1(
                                          _vm.addForm.annexList1,
                                          index
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      class: item.fileIconShow,
                                      staticStyle: {
                                        color: "#316CF5!important"
                                      }
                                    }),
                                    _c(
                                      "span",
                                      { attrs: { title: item.fileName } },
                                      [_vm._v(_vm._s(item.fileName))]
                                    )
                                  ]
                                )
                              ]),
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "el-upload-list__item check",
                                    style: item.check
                                      ? "margin-left:18px;display:none;"
                                      : "margin-left:18px;cursor:pointer"
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-upload-success el-icon-circle-check"
                                    })
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "check",
                                    style: item.check
                                      ? "margin-left:18px;cursor:pointer"
                                      : "margin-left:18px;display:none;"
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-close",
                                      on: {
                                        click: function($event) {
                                          return _vm.deletionPositive1(index)
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "合同附件：",
                    prop: "annexList2"
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "file-upload",
                        {
                          ref: "msPositive2",
                          attrs: {
                            accept: ".pdf,.PDF",
                            size: 50 * 1024 * 1024,
                            sizeInfo: "50M"
                          },
                          on: {
                            showFileList: _vm.clickPositive2,
                            success: _vm.clickPositiveSuccess2,
                            error: function($event) {
                              return _vm.clickError()
                            }
                          }
                        },
                        [
                          _vm.$zwxBase.verifyIsBlank(_vm.addForm.annexList2) ||
                          _vm.addForm.annexList2.length < 1
                            ? _c(
                                "div",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass:
                                        "zwx-button zwx-button-icontext-28",
                                      attrs: { icon: "el-icon-upload2" }
                                    },
                                    [_vm._v("上传")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm.addForm.annexList2.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "show-file",
                              staticStyle: { display: "inline-block" }
                            },
                            _vm._l(_vm.addForm.annexList2, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: item.fileName,
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  },
                                  on: {
                                    mouseenter: function($event) {
                                      return _vm.mouseenter2(index)
                                    },
                                    mouseleave: function($event) {
                                      return _vm.mouseleave2(index)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "file-name-hidden" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: {
                                            color: "#194DFF !important",
                                            "margin-left": "0px",
                                            cursor: "pointer"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openFilePreview1(
                                                _vm.addForm.annexList2,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            class: item.fileIconShow,
                                            staticStyle: {
                                              color: "#316CF5!important"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            { attrs: { title: item.fileName } },
                                            [_vm._v(_vm._s(item.fileName))]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "el-upload-list__item check",
                                        style: item.check
                                          ? "margin-left:18px;display:none;"
                                          : "margin-left:18px;cursor:pointer"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-upload-success el-icon-circle-check"
                                        })
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "check",
                                        style: item.check
                                          ? "margin-left:18px;cursor:pointer"
                                          : "margin-left:18px;display:none;"
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-close",
                                          on: {
                                            click: function($event) {
                                              return _vm.deletionPositive2(
                                                index
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              on: {
                click: function($event) {
                  return _vm.save(0)
                }
              }
            },
            [_vm._v("暂存")]
          ),
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.save(1)
                }
              }
            },
            [_vm._v("下一步")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }