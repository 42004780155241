<template>
  <div class="detection-report-basic-info-edit">
    <zwx-step :index="1" :dataList="dataList"></zwx-step>
    <el-divider class="base-divider" />
    <el-form class="zwx-form edit-form" ref="addForm" key="addForm" :model="addForm" :rules="rules" label-position="right" @submit.native.prevent>
      <div class="edit-row">
        <el-form-item label-width="110px" label="单位名称：" prop="employerName">
          <unit-select ref="unitSelectRef" width="350px" v-model="addForm.employerName" placeholderText="请输入5个字符搜索" :requestUrl="requestUrl" :ifShowMoreBtn="false" :ifNeedMore="false" @unitNameSearch="unitNameSearch" @unitNameDel="unitNameDel" @change="unitNameSelect" @loading="data => $emit('loading', data)" />
        </el-form-item>
        <el-form-item label-width="110px" label="社会信用代码：" prop="creditCode">
          <el-input class="zwx-input" style="width:350px !important" v-model="addForm.creditCode" placeholder="请输入社会信用代码" clearable :disabled="creditCodeDisabled" />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="行政区划：" prop="bizZone">
          <zwx-select-area ref="zoneCodeArea" class="select-component" placeholder="请选择" @change="nativePlaceChange" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="true" :disabled="zoneDisabled" />
        </el-form-item>

        <el-form-item label-width="110px" label="报告编号：" prop="reportCode">
          <el-input style="width:350px !important" class="zwx-input" v-model.trim="addForm.reportCode" placeholder="" clearable maxlength="200" />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="报告日期：" prop="reportDate">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width:350px !important" v-model="addForm.reportDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" :picker-options="$validate.noOverToday()" />
        </el-form-item>
        <el-form-item label-width="110px" label="检测类型：" prop="detectionType">
          <el-radio-group style="min-width:260px !important;" class="zwx-radio-group" v-model="addForm.detectionType">
            <el-radio class="zwx-radio" :label="1">定期检测</el-radio>
            <el-radio class="zwx-radio" :label="3">监督检测</el-radio>
            <el-radio class="zwx-radio" :label="2">现状评价</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="现场采样日期：" prop="samplingBeginDate" style="margin-right: 0px !important;">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" v-model="addForm.samplingBeginDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" :picker-options="$validate.noBigDateNoOverToday($data['addForm'], 'samplingEndDate', true)" />
          <span style="margin: 0px 8px;">—</span>
        </el-form-item>
        <el-form-item prop="samplingEndDate" style="margin-left: 0px">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" v-model="addForm.samplingEndDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" :picker-options="$validate.noSmallDateNoOverToday($data['addForm'], 'samplingBeginDate', true)" />
        </el-form-item>
        <el-form-item label-width="111px" label="陪同人：" prop="accompanyingPerson">
          <el-input style="width:350px !important" class="zwx-input" v-model.trim="addForm.accompanyingPerson" placeholder="" clearable maxlength="50" />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="检验日期：" prop="detectionBeginDate" style="margin-right: 0px !important;">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" v-model="addForm.detectionBeginDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" :picker-options="$validate.noBigDate($data['addForm'], 'detectionEndDate', true)" />
        </el-form-item>
        <span style="margin: 0px 8px;">—</span>
        <el-form-item prop="detectionEndDate" style="margin-left: 0px">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" v-model="addForm.detectionEndDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" :picker-options="$validate.noSmallDate($data['addForm'], 'detectionBeginDate', true)" />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="环境条件：" prop="enviromentCondition">
          <el-input class="zwx-textarea" type="textarea" v-model.trim="addForm.enviromentCondition" style="width: 804px !important;" :autosize="{ minRows: 4 }" maxlength="2000" placeholder="正文内容" clearable />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="报告附件：" prop="annexList1">
          <div style="display:flex;">
            <file-upload ref="msPositive1" @showFileList="clickPositive" @success="clickPositiveSuccess" @error="clickError()" accept=".pdf,.PDF" :size="50 * 1024 * 1024" sizeInfo="50M">
              <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2">上传</el-button>
            </file-upload>
            <span class="upload-tip">支持多个pdf格式文件，不大于50M</span>
          </div>
          <div style="display: inline-block" class="show-file" v-if="addForm.annexList1.length > 0">
            <div style="display: flex;align-items: center;" v-for="(item, index) in addForm.annexList1" :key="item.fileName" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)">
              <div class="file-name-hidden">
                <a style="color:#194DFF !important;margin-left:0px;cursor:pointer;" @click="openFilePreview1(addForm.annexList1, index)">
                  <i :class="item.fileIconShow" style="color:#316CF5!important"></i>
                  <span :title="item.fileName">{{ item.fileName }}</span>
                </a>
              </div>
              <div>
                <span class="el-upload-list__item check" :style="item.check ? 'margin-left:18px;display:none;' : 'margin-left:18px;cursor:pointer'"><i class="el-icon-upload-success el-icon-circle-check"></i></span>
                <span class="check" :style="item.check ? 'margin-left:18px;cursor:pointer' : 'margin-left:18px;display:none;'"><i class="el-icon-close" @click="deletionPositive1(index)"></i></span>
              </div>
            </div>
          </div>
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="合同附件：" prop="annexList2">
          <div style="display:flex;">
            <file-upload ref="msPositive2" @showFileList="clickPositive2" @success="clickPositiveSuccess2" @error="clickError()" accept=".pdf,.PDF" :size="50 * 1024 * 1024" sizeInfo="50M">
              <div style="text-align: left" v-if="$zwxBase.verifyIsBlank(addForm.annexList2) || addForm.annexList2.length < 1">
                <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2">上传</el-button>
              </div>
            </file-upload>
            <div style="display: inline-block" class="show-file" v-if="addForm.annexList2.length > 0">
              <div style="display: flex;align-items: center;" v-for="(item, index) in addForm.annexList2" :key="item.fileName" @mouseenter="mouseenter2(index)" @mouseleave="mouseleave2(index)">
                <div class="file-name-hidden">
                  <a style="color:#194DFF !important;margin-left:0px;cursor:pointer;" @click="openFilePreview1(addForm.annexList2, index)">
                    <i :class="item.fileIconShow" style="color:#316CF5!important"></i>
                    <span :title="item.fileName">{{ item.fileName }}</span>
                  </a>
                </div>
                <div>
                  <span class="el-upload-list__item check" :style="item.check ? 'margin-left:18px;display:none;' : 'margin-left:18px;cursor:pointer'"><i class="el-icon-upload-success el-icon-circle-check"></i></span>
                  <span class="check" :style="item.check ? 'margin-left:18px;cursor:pointer' : 'margin-left:18px;display:none;'"><i class="el-icon-close" @click="deletionPositive2(index)"></i></span>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" @click="save(0)">暂存</el-button>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="save(1)">下一步</el-button>
    </fixed-footer>
  </div>
</template>

<script>
import ZwxStep from '@/views/agency/workplace_detection/components/ZwxStep'
export default {
  name: 'DetectionReportResultBasicInfoEdit',
  components: { ZwxStep },
  props: {
    rid: { type: String, default: '' },
  },
  data() {
    return {
      dataList: [
        { index: 0, text: '基本信息' },
        { index: 1, text: '检测结果' },
        { index: 2, text: '检测结论及建议' },
      ],
      requestUrl: '/zky/employer/registerGetParentUnitInfoByKeyword-0',
      creditCodeDisabled: true,
      zoneDisabled: true,
      zoneCode12From: '000000000000',
      users: this.$store.state.users,
      api: this.$store.state.api,
      employerUuid: undefined,
      addForm: {
        rid: this.$route.params.rid,
        detectionType: null,
        reportType: this.$route.params.reportType,
        organName: null,
        reportDate: null,
        reportName: null,
        reportCode: null,
        annexList1: [],
        annexList2: [],
        organCreditCode: null,
        organUid: null,
        dataState: null,
        samplingBeginDate: null,
        samplingEndDate: null,
        accompanyingPerson: null,
        detectionBeginDate: null,
        detectionEndDate: null,
        enviromentCondition: null,
        employerName: '',
        creditCode: '',
        bizZone: '',
        zoneType: '',
      },
      rules: {
        detectionType: [{ required: true, message: '请选择检测类型', trigger: ['change', 'blur'] }],
        organName: [{ required: true, message: '请输入检测机构', trigger: ['change', 'blur'] }],
        reportDate: [{ required: true, message: '请选择报告日期', trigger: ['change', 'blur'] }],
        reportName: [{ required: true, message: '请输入检测报告名称', trigger: ['change', 'blur'] }],
        samplingBeginDate: [{ required: true, message: '请选择现场采样开始日期', trigger: ['change'] }],
        samplingEndDate: [{ required: true, message: '请选择现场采样结束日期', trigger: ['change'] }],
        detectionBeginDate: [{ required: true, message: '请选择检验开始日期', trigger: ['change'] }],
        detectionEndDate: [{ required: true, message: '请选择检验结束日期', trigger: ['change'] }],
        reportCode: [
          { required: true, message: '请输入报告编号', trigger: ['change', 'blur'] },
          { validator: this.reportCodeIfExist, trigger: 'blur' },
        ],
        annexList1: [{ required: true, message: '请上传报告附件', trigger: ['change', 'blur'] }],
        employerName: [
          { required: true, message: '请输入单位名称', trigger: ['blur'] },
          { validator: this.validateUnitName, trigger: ['blur', 'change'] },
        ],
        creditCode: [
          { required: true, message: '请输入社会信用代码', trigger: ['change'] },
          { validator: this.validateCreditCode, trigger: ['blur'] },
        ],
        bizZone: [
          { required: true, message: '请选择行政区划', trigger: ['change'] },
          { validator: this.validateBizZone, trigger: ['blur', 'change'] },
        ],
      },
      hazardOrgList:[],//危害因素列表
      hazardList:[],//危害因素层级
    }
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs
    },
  },
  created() { },
  mounted() {
    this.addForm.organName = this.$store.state.users.fkByUnitId.unitName
    this.$refs.zoneCodeArea.initSearch()
    // if (this.$zwxBase.verifyIsNotBlank(this.$route.params.employerUuid)) {
    //   this.employerUuid = this.$route.params.employerUuid
    // }
    if (!this.$zwxBase.verifyIsBlank(this.$route.params.rid)) {
      this.queryDetectionReportBasicInfo(this.$route.params.rid)
    }
    this.msHazardsSelectSearch()
  },
  methods: {
    
      //危害因素查询
      msHazardsSelectSearch: function () {
      let data = {}
      this.$emit('loading', true)
      this.$system.get(
              this.api + '/basic/info/getHazardsStandardExpList-1',
              data,
              true,
              true,
              data => {
                this.$emit('loading', false)
                if (data.type == '00') {
                  this.hazardOrgList = data.dataList
                  this.hazardList=this.manyListFul(data.dataList, '')
                } else if (data.type === '99') {
                  this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
                } else {
                  this.$system.notify('错误', data.mess, 'error')
                }
              },
              data => {
                this.$emit('loading', false)
                this.$system.notify('错误', data.mess, 'error')
              }
      )
    },
    manyListFul(data, pid) {
      //递归方法 生成 json tree 数据
      var result = [],
              temp,
              obj
      data.some(e => {
        let LevelNoArr = e.levelCodeNo.split('.')
        if ((e.levelCodeNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
          obj = { ...e }
          temp = this.manyListFul(data, e.codeNo)
          if (temp.length > 0) {
            obj.children = temp
          }
          result.push(obj)
        }
      })
      return result
    },

    /**
     * 地区校验
     */
    validateBizZone(rule, value, callback) {
      if (this.$zwxBase.verifyIsBlank(this.$refs.zoneCodeArea.getChooseArea())) {
        callback(new Error('请选择'))
      }
      let zoneType = this.$refs.zoneCodeArea.getChooseArea().zoneType
      let childNum = this.$refs.zoneCodeArea.getChooseArea().childNum
      if (this.$zwxBase.verifyIsNotBlank(zoneType) && this.$zwxBase.verifyIsNotBlank(childNum)) {
        if (childNum != 0) {
          callback()
        } else {
          if (zoneType > 4) {
            callback(new Error('请选择到街道级'))
          }
        }
      }
      callback()
    },
    //行政区划
    nativePlaceChange() {
      if (this.$refs.zoneCodeArea.getChooseArea()) {
        this.addForm.zoneType = this.$refs.zoneCodeArea.getChooseArea().zoneType
        this.addForm.bizZone = this.$refs.zoneCodeArea.getChooseArea() == null ? null : this.$refs.zoneCodeArea.getChooseArea().zoneCode12
      }
    },
    /**
     * 单位名称长度校验
     */
    validateUnitName(rule, value, callback) {
      if (value != undefined && value != null && value != '' && value.length < 5) {
        callback(new Error('至少输入5个字符进行检索'))
      }
      callback()
    },
    //机构未搜索到数据
    unitNameSearch() {
      this.creditCodeDisabled = true
      this.zoneDisabled = true
      this.$refs.unitSelectRef.unitNameSearchVisible = false
      this.$system.notify('提示', '未检索到单位信息', 'info')
    },
    //机构清空回调方法
    unitNameDel() {
      this.zoneCode12From = '440000000000'
      this.$refs.zoneCodeArea.initSearch()
      this.creditCodeDisabled = true
      this.zoneDisabled = true
      this.searchUnitList = []
      this.addForm.creditCode = ''
      this.addForm.employerName = ''
      this.addForm.zoneType = ''
      //天眼查组件清空赋值------开始
      this.$refs.unitSelectRef.searchOrDelIcon = 'el-icon-search'
      this.$refs.unitSelectRef.unitNameDisabled = false
      //给天眼查组件清空赋值------结束
      this.$nextTick(() => {
        this.$refs['addForm'].clearValidate()
      })
    },
    // 单位信息选择回调方法
    unitNameSelect(item) {
      //客户信息
      this.addForm.employerName = item.unitName
      this.addForm.creditCode = item.creditCode
      this.creditCodeDisabled = true
      this.employerUuid = item.unitUid || ''
      this.addForm.bizZone = item.bizZone || ''
      this.zoneDisabled = item.bizZone != undefined
      if (item.bizZone) {
        this.zoneCode12From = '000000000000'
      } else {
        this.zoneCode12From = '440000000000'
      }
      this.$refs.zoneCodeArea.initSearch(this.addForm.bizZone)
    },
    queryDetectionReportBasicInfo(rid) {
      let data = {
        rid: rid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/oh/workplace/detection/getTdDetectionReport-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.addForm.rid = data.detectionReportDetail.rid
            this.addForm.reportType = data.detectionReportDetail.reportType
            this.addForm.dataState = data.detectionReportDetail.dataState
            this.addForm.detectionType = data.detectionReportDetail.detectionType
            this.addForm.organName = data.detectionReportDetail.organName
            this.addForm.reportDate = data.detectionReportDetail.reportDate
            this.addForm.reportName = data.detectionReportDetail.reportName
            this.addForm.reportCode = data.detectionReportDetail.reportCode
            this.addForm.organCreditCode = data.detectionReportDetail.organCreditCode
            this.addForm.organUid = data.detectionReportDetail.organUid
            this.addForm.accompanyingPerson = data.detectionReportDetail.accompanyingPerson
            this.addForm.detectionBeginDate = data.detectionReportDetail.detectionBeginDate
            this.addForm.detectionEndDate = data.detectionReportDetail.detectionEndDate
            this.addForm.enviromentCondition = data.detectionReportDetail.enviromentCondition
            this.addForm.samplingBeginDate = data.detectionReportDetail.samplingBeginDate
            this.addForm.samplingEndDate = data.detectionReportDetail.samplingEndDate
            this.addForm.employerName = data.detectionReportDetail.employerName
            this.addForm.bizZone = data.detectionReportDetail.bizZone
            this.$refs.zoneCodeArea.initSearch(this.addForm.bizZone)
            this.addForm.creditCode = data.detectionReportDetail.employerCreditCode

            //给天眼查组件赋值后置灰------开始
            this.$refs.unitSelectRef.searchOrDelIcon = 'el-icon-delete'
            this.$refs.unitSelectRef.unitNameDisabled = true
            //给天眼查组件赋值后置灰------结束
            this.addForm.organCreditCode = data.detectionReportDetail.organCreditCode
            this.employerUuid = data.detectionReportDetail.employerUuid
            this.addForm.annexList1 = []
            data.detectionReportDetail.reportAnnexExpList1.forEach(annex => {
              this.addForm.annexList1.push({
                fileName: annex.fileName,
                filePath: annex.filePath,
                check: false,
                fileIconShow: 'colorful chis-icon-colorful-pdf',
              })
            })
            this.addForm.annexList2 = []
            data.detectionReportDetail.reportAnnexExpList2.forEach(annex => {
              this.addForm.annexList2.push({
                fileName: annex.fileName,
                filePath: annex.filePath,
                check: false,
                fileIconShow: 'colorful chis-icon-colorful-pdf',
              })
            })
          } else if (data.type === '99') {
            this.$router.push({ name: 'MsError', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    clickPositive(showList) {
      showList.forEach(item => {
        let flag = this.addForm.annexList1.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.addForm.annexList1.push({
          fileName: item,
          fileIconShow: this.dealfilePrefix(item),
          check: false,
        })
      })
      this.generateFilesUid()
      if (this.addForm.annexList1 != null && this.addForm.annexList1 != undefined && this.addForm.annexList1.length > 0) {
        this.$refs.addForm.clearValidate('annexList1')
      }
    },
    clickPositive2(showList) {
      showList.forEach(item => {
        let flag = this.addForm.annexList2.some(e => e.fileName == item)
        if (flag) {
          this.$refs.msPositive2.deleteIndex(showList.length - 1)
          return
        }
        this.addForm.annexList2.push({
          fileName: item,
          fileIconShow: this.dealfilePrefix(item),
          check: false,
        })
      })
      this.generateFilesUid2()
      if (this.addForm.annexList2 != null && this.addForm.annexList2 != undefined && this.addForm.annexList2.length > 0) {
        this.$refs.addForm.clearValidate('annexList2')
      }
    },
    dealfilePrefix(file) {
      let prefix = file.substring(file.indexOf('.'))
      let fileIconShow = ''
      if (prefix === '.pdf' || prefix === '.PDF') {
        fileIconShow = 'colorful chis-icon-colorful-pdf'
      } else if (prefix === '.doc' || prefix === '.docx' || prefix === '.DOC' || prefix === '.DOCX') {
        fileIconShow = 'colorful chis-icon-colorful-word'
      } else if (prefix === '.png' || prefix === '.jpeg' || prefix === '.jpg' || prefix === '.JPG' || prefix === '.JPEG' || prefix === '.PNG') {
        fileIconShow = 'colorful chis-icon-colorful-jpg'
      }
      return fileIconShow
    },
    clickPositiveSuccess(fileList) {
      if (this.$zwxBase.verifyIsNotBlank(this.addForm.annexList1) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.addForm.annexList1) {
          for (let j in fileList) {
            if (this.addForm.annexList1[i].fileName === fileList[j].fileName) {
              this.addForm.annexList1[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      this.$emit('loading', false)
    },
    clickPositiveSuccess2(fileList) {
      if (this.$zwxBase.verifyIsNotBlank(this.addForm.annexList2) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.addForm.annexList2) {
          for (let j in fileList) {
            if (this.addForm.annexList2[i].fileName === fileList[j].fileName) {
              this.addForm.annexList2[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      this.$emit('loading', false)
    },
    clickError() { },
    mouseenter(index) {
      this.addForm.annexList1[index].check = true
    },
    mouseleave(index) {
      this.addForm.annexList1[index].check = false
    },
    mouseenter2(index) {
      this.addForm.annexList2[index].check = true
    },
    mouseleave2(index) {
      this.addForm.annexList2[index].check = false
    },
    /**
     * 单个文件预览
     */
    openFilePreview(filename, filePath) {
      let previewFileList = []
      let item = {
        fileName: filename,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    // 删除检测报告协议
    deletionPositive1(index) {
      let fileName = this.addForm.annexList1[index].fileName
      this.addForm.annexList1.splice(index, 1)
      this.$refs.msPositive1.deleteIndex(index)
      let fileList = this.$refs.msPositive1.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.msPositive1.deleteIndex(j)
            fileList = this.$refs.msPositive1.showFileList || []
            continue
          }
        }
      }
    },
    // 删除检测报告协议
    deletionPositive2(index) {
      let fileName = this.addForm.annexList2[index].fileName
      this.addForm.annexList2.splice(index, 1)
      this.$refs.msPositive2.deleteIndex(index)
      let fileList = this.$refs.msPositive2.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.msPositive2.deleteIndex(j)
            fileList = this.$refs.msPositive2.showFileList || []
            continue
          }
        }
      }
    },
    peOrganSelectChange(value, row) {
      this.$set(row, 'organUid', value.unitUid)
      this.$set(row, 'organName', this.$zwxBase.verifyIsBlank(value.organName) ? value.unitName : value.organName)
      this.$set(row, 'organCreditCode', value.creditCode)
    },
    save(operateType) {
      this.addForm.dataState = 0
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          // if (this.$zwxBase.verifyIsBlank(this.addForm.organCreditCode)) {
          //   this.$system.notify('错误', '请选择搜索到的体检机构', 'error')
          //   return
          // }
          if (this.$zwxBase.verifyIsNotBlank(this.addForm.annexList1) && this.$zwxBase.verifyIsNotBlank(this.addForm.annexList1[0].filePath)) {
            this.submit(operateType)
          }
        }
      })
    },
    // 上传检测报告 文件的UID
    generateFilesUid() {
      this.$emit('loading', true)
      let data = {
        employerId: this.employerBasicId,
        folder: '/workplace_detection',
      }
      this.$system.post(this.api + '/zky/employer/generateFilesUid-1', data, true, true, this.generateFilesUidSuccess, {})
    },
    generateFilesUidSuccess(data) {
      if (data.type === '00') {
        this.$refs.msPositive1.submit(data.uid)
      } else {
        this.$emit('loading', false)
        this.$system.notify('错误', data.mess, 'error')
      }
    },
    generateFilesUid2() {
      this.$emit('loading', true)
      let data = {
        employerId: this.employerBasicId,
        folder: '/workplace_detection',
      }
      this.$system.post(this.api + '/zky/employer/generateFilesUid-1', data, true, true, this.generateFilesUidSuccess2, {})
    },
    generateFilesUidSuccess2(data) {
      if (data.type === '00') {
        this.$refs.msPositive2.submit(data.uid)
      } else {
        this.$emit('loading', false)
        this.$system.notify('错误', data.mess, 'error')
      }
    },
    submit(operateType) {
      let data = {
        rid: this.addForm.rid,
        reportType: this.addForm.reportType,
        dataState: this.addForm.dataState,
        detectionType: this.addForm.detectionType,
        organName: this.addForm.organName,
        reportDate: this.addForm.reportDate,
        reportName: this.addForm.reportName,
        reportCode: this.addForm.reportCode,
        reportAnnexExpList1: this.addForm.annexList1,
        reportAnnexExpList2: this.addForm.annexList2,
        organCreditCode: this.addForm.organCreditCode,
        organUid: this.addForm.organUid,
        accompanyingPerson: this.addForm.accompanyingPerson,
        detectionBeginDate: this.addForm.detectionBeginDate,
        detectionEndDate: this.addForm.detectionEndDate,
        enviromentCondition: this.addForm.enviromentCondition,
        samplingBeginDate: this.addForm.samplingBeginDate,
        samplingEndDate: this.addForm.samplingEndDate,
        fillingStep: 1, //填报阶段
        employerUuid: this.employerUuid,
        employerCreditCode: this.addForm.creditCode,
        employerName: this.addForm.employerName,
        bizZone: this.addForm.bizZone,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/workplace/detection/addOrModifyTdDetectionReportFromOrgan-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$system.notify('成功', '提交成功', 'success')
            this.addForm.rid = data.rid
            if (operateType == 1) {
              this.$router.push({ name: 'DetectionReportDetectionResult', params: { rid: this.addForm.rid, employerUuid: this.employerUuid } })
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'MsError', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    //报告编号查重
    reportCodeIfExist(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        let data = {
          organUid: this.$store.state.users.fkByUnitId.uid, //检测机构uid
          reportType: this.addForm.reportType,
          reportCode: this.addForm.reportCode, //检测报告编码
          organName: this.addForm.organName, //检测机构名称
          rid: this.addForm.rid,
        }
        this.$system.postJson(this.api + '/oh/workplace/detection/getReportCodeIfExist-1', data, true, true, data => {
          if (data.type === '00') {
            if (data.ifExist) {
              return callback(new Error('报告编号重复'))
            } else {
              return callback()
            }
          } else {
            return callback(new Error(data.mess))
          }
        })
      }
    },
    back() {
      this.$router.replace({ name: this.breadcrumbs[this.breadcrumbs.length - 2].name })
    },
  },
}
</script>

<style lang="less" scoped>
.detection-report-basic-info-edit {
  .select-component {
    /deep/ .zwx-input {
      width: 350px !important;
      cursor: pointer;
    }

    /deep/ .el-input__suffix {
      right: 6px !important;
    }

    /deep/ .el-input__inner {
      width: 350px;
    }
    /deep/ .zwx-input .el-input__suffix .el-input__suffix-inner .el-input__icon {
      margin-top: 0;
      line-height: 32px !important;
    }
  }
}

/deep/.detection-report-dialog .el-dialog {
  width: 680px !important;
  min-width: 680px !important;
}
.upload-tip {
  margin: 9px 0 0 9px;
  height: 12px;
  font-size: 12px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
  font-weight: 400;
  text-align: justify;
  color: #595959;
  line-height: 12px;
}
</style>
<style lang="less">
.file-name-hidden {
  display: inline-block;
  max-width: 320px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
